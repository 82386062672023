<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>打印机列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-button @click="handleRegisterShow" class="a-add-btn" type="primary"
      >增加</el-button
    >
    <el-table class="a-table" :data="tableData" @row-click="handleRowClick">
      <el-table-column label="打印机编号" prop="printerNo">
        <template slot-scope="scope">
          <span style="cursor: pointer">{{ scope.row.printerNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="打印机名称" prop="printerName" />
      <el-table-column label="工艺" prop="technology" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag size="small" :type="types[scope.row.state]">{{
            scope.row.state ? status[scope.row.state] : ""
          }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="pageInfo.pageSizes"
        :page-size="pageInfo.pageSize"
        :layout="pageInfo.layout"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 增加打印机 -->
    <el-dialog
      :before-close="handleRegisterClose"
      :visible.sync="visible.register"
      title="增加打印机"
      width="480px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
    >
      <el-form ref="register" :model="form" label-width="72px">
        <el-form-item label="名称">
          <el-input v-model="form.printerName"></el-input>
        </el-form-item>
        <el-form-item label="工艺">
          <el-select
            v-model="form.technology"
            placeholder="请选择"
            style="width: 100%"
          >
            <template v-for="(item, index) in technologyOptions">
              <el-option :label="item" :value="item" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item class="signup-btn">
          <el-button @click="handleRegisterClose">取消</el-button>
          <el-button type="primary" @click="handleRegister">增加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      form: {},
      visible: {
        register: false,
      },
      printerInfo: {},
      code: "",
      status: this.$store.state.status,
      types: this.$store.state.types,
      /**分页 */
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: true,
      serviceId: localStorage.getItem("serviceId"),
      technology: undefined,
      technologyOptions: [],
    };
  },
  methods: {
    getPrinters() {
      this.loading = true;
      const page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      const manufacturerId = localStorage.getItem("serviceId");
      this.$axios.getPrinters({ ...page, manufacturerId }).then((res) => {
        if (!res.error) {
          this.pageInfo.total = res.totalCount;
          res.data.map((customer) => {
            return (customer.createTime = this.$utils.dateToString(
              customer.createTime
            ));
          });
          this.tableData = res.data;
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
        this.loading = false;
      });
    },
    /**获取工艺列表请求 */
    getTechnologyOptions() {
      this.$axios.getTechnologyOptions().then((res) => {
        if (!res.error) {
          this.technologyOptions = res;
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
    },
    /**增加 */
    handleRegister() {
      let params = {
        ...this.$refs["register"].model,
        manufacturerID: this.serviceId,
      };
      console.log(params);
      this.$axios.postPrinter(params).then((res) => {
        if (!res.error) {
          console.log(res);
          this.$message({
            message: "打印机增加成功",
            type: "success",
          });
          this.getPrinters();
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
      this.handleRegisterClose();
    },
    handleRegisterShow() {
      this.visible.register = true;
      this.getTechnologyOptions();
    },
    handleRegisterClose() {
      this.visible.register = false;
      this.getPrinters();
    },
    /**打印机详情 */
    handleRowClick(row) {
      // console.log(row, col, e);
      localStorage.setItem(
        "printer",
        JSON.stringify({ printerId: row.id, printerName: row.printerName })
      );
      this.$router.push(`printer/${row.id}`);
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getPrinters();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getPrinters();
    },
    stateToType(state) {
      switch (state) {
        case "Unbound": {
          return "info";
        }
        case "Free": {
          return "primary";
        }
        case "Printing": {
          return "success";
        }
        case "Disable": {
          return "danger";
        }
        default: {
          return "info";
        }
      }
    },
  },
  mounted() {
    this.getPrinters();
  },
};
</script>
